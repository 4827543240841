import React from "react";

import "./style.scss";

export default function CodeOfConductContent() {
  return (
    <>
      <h2>Code Of Conduct</h2>
    </>
  );
}
